

// import telegram_icon from "../assets/icons/telegram.png";
import email_icon from "../assets/icons/email.png"
// import { useTelegram } from "../modals/telegram";
import { useContactUs } from "../modals/contact_us";

function Telegram_Support() {

    let { open_contact_us } = useContactUs();
    // let { open_telegram } = useTelegram();

    return (
        <div className="tg_support opacity_hover" onClick={open_contact_us}>
            <img className="extra_email_icon" src={email_icon}/>
        </div>
    )
}

export default Telegram_Support;